import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@ui/button';
import { DropdownArrow } from '@ui/dropdown/DropdownArrow';
import { InputStyleType } from '@uiTypes/InputStyleType';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { jsonToString } from '@common/methods/jsonToString';
import { trans } from '../../modules/translation/translate';
import { enhanceComponent } from '../../wrappers/enhanceComponent';
import transactionService from '../../../../modules/transactions/transactionsService';
import { DropdownStyle } from './dropdown.style';

class Dropdown extends React.PureComponent {
	static propTypes = {
		title: PropTypes.any,
		className: PropTypes.string,
		list: PropTypes.array,
		active: PropTypes.any.isRequired,
		onClick: PropTypes.func,
		width: PropTypes.any,
		close: PropTypes.func,
		add: PropTypes.func,
		show: PropTypes.func,
		id: PropTypes.string.isRequired,
		get: PropTypes.func,
		trans: PropTypes.bool,
		help: PropTypes.any,
		localTheme: PropTypes.any,
		scrollElement: PropTypes.any,
		cssProp: PropTypes.any,
		isContent: PropTypes.bool,
		namespace: PropTypes.string,
	};

	constructor(props) {
		super(props);
		this.addContent();
	}

	componentDidUpdate(prevProps) {
		if (
			transactionService.isListDifferent(
				this.props.list,
				prevProps.list,
				this.props.list?.[0]?.id ? ['id'] : undefined,
			) ||
			(this.props.isContent !== prevProps.isContent && !this.props.isContent)
		) {
			this.addContent();
		}
	}

	componentWillUnmount() {
		if (this.props.list) {
			this.props.close(this.props.id);
		}
	}

	addContent = () => {
		if (!this.props.list) {
			return;
		}
		const lastContent = this.props.get(this.props.id);
		const content = {
			scrollElement: this.props.scrollElement,
			content: this.props.list,
			title: this.props.title,
			onClick: this.props.onClick,
			width: this.props.width,
			className: this.props.className,
			css: this.props.cssProp,
			trans: this.props.trans,
		};
		if (lastContent && jsonToString(content) === jsonToString(lastContent)) {
			return;
		}
		this.props.add(this.props.id, content);
	};

	hideList = () => {
		if (this.props.list) {
			this.props.show(this.props.id, false);
		}
	};

	showList = () => {
		if (this.props.list) {
			this.props.show(this.props.id, true);
		}
	};

	toggleList = () => {
		console.log('toggleList', this.props.id, {
			props: this.props,
		});
		if (this.props.list) {
			this.props.show(this.props.id);
		}
	};

	render() {
		const isActiveString = typeof this.props.active === 'string';
		return (
			<DropdownStyle
				localTheme={this.props.localTheme}
				data-id={this.props.id}
				// onMouseEnter={this.showList}
				// onTouchStart={this.toggleList}
				onClick={isActiveString ? undefined : this.toggleList}
				// onMouseLeave={this.hideList}
				className={this.props.className}
			>
				{isActiveString ? (
					<Button
						onClick={this.toggleList}
						name="dropdown-active"
						styleType={ButtonStyleType.SECONDARY}
						borderType={InputStyleType.SQUARE}
						className="icon-wrapper"
						label={
							this.props.trans
								? trans({
										label: this.props.active,
										namespace: this.props.namespace,
								  })
								: this.props.active
						}
					>
						{this.props.list && <DropdownArrow />}
					</Button>
				) : (
					this.props.active
				)}
				{this.props.help}
			</DropdownStyle>
		);
	}
}

Dropdown = enhanceComponent({
	Dropdown,
});

export { Dropdown };
