import styled, { css } from 'styled-components';
import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { LinkTo } from '../../default/LinkTo/LinkTo';
import { Image } from '../../default/Image/Image';

const SCALE = (236 / 416) * 100;

const OFFER_ELEMENT_STYLES = {
	[GameType.DICE]: {
		default: css`
			width: 60% !important;
		`,
		hover: css`
			transform: scale(1.1) rotate(-10deg);
		`,
	},
	[GameType.LIMBO]: {
		default: css`
			width: 85% !important;
		`,
		hover: css`
			transform: scale(1.05);
		`,
	},
	[GameType.HILO]: {
		default: css`
			width: 65% !important;
		`,
		hover: css`
			transform: scale(1.05);
		`,
	},
	[GameType.SPORTS]: {
		default: css`
			padding-top: 5% !important;
			width: 80% !important;
		`,
		hover: css`
			transform: scale(1.05) translateY(-5%);
		`,
	},
	[GameType.SLOTS]: {
		default: css`
			width: 65% !important;
		`,
		hover: css`
			transform: scale(1.06);
		`,
	},
};

export const StyledImageElementWrapper = styled.div`
	align-items: center;
	display: flex !important;
	height: 100%;
	justify-content: center;
	left: 0;
	overflow: visible !important;
	position: absolute;
	top: 0;
	width: 100%;
`;

export const OfferBackgroundStyled = styled(Image)`
	border-radius: 12px;
	left: 0;
	position: absolute;
	top: 0;
`;

export const StyledOfferButton = styled(LinkTo)`
	${boxNoHeight};
	height: auto;
	overflow: hidden;
	padding-bottom: ${SCALE}%;
	position: relative;
	text-transform: capitalize;

	${OfferBackgroundStyled} {
		width: 100%;
	}

	img {
		transition: ${(props) =>
			calcStyle({
				a: 2,
				b: props.theme.layout.transition,
				mode: '*',
				suffix: 'ms',
			})};
	}

	&:hover {
		${OfferBackgroundStyled} {
			opacity: 0.85;
			transform: scale(0.99);
		}
	}
`;
export const StyledMainPageOfferButton = styled(StyledOfferButton)<{
	$offerName: GameType;
}>`
	span {
		bottom: 0;
		font-family: ${(props) => props.theme.text.fonts.Regular};
		left: 0;
		padding: 15px;
		position: absolute;
	}

	.offer-element {
		position: relative;
		${(props) => OFFER_ELEMENT_STYLES[props.$offerName]?.default}
	}

	&:hover {
		opacity: 1;

		.offer-element {
			${(props) => OFFER_ELEMENT_STYLES[props.$offerName]?.hover}
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		span {
			text-shadow: 1px 1px 1px black;
		}
	}
`;
