import styled, { css } from 'styled-components';
import { boxNoHeight } from '../../modules/style/defaults';

const getProps = (props) => {
	if (!props.localTheme || !Object.keys(props.localTheme).length) {
		return '';
	}
	if (props.localTheme && props.localTheme.default) {
		return css`
			.button {
				font-family: ${(props) => props.theme.text.fonts.Bold};
				height: unset;
				margin: 0 !important;
				padding: 7px 15px;

				&.icon-wrapper {
					padding-right: 30px;

					i,
					svg {
						right: 10px;
					}
				}
			}
		`;
	}
};

export const DropdownStyle = styled.div.attrs(() => ({
	className: 'dropdown',
}))`
	${boxNoHeight};
	position: relative;
	width: unset;
	padding-bottom: 7px;
	margin-bottom: -7px;

	* {
		cursor: pointer !important;
	}

	&[data-id*='when-name'] {
		* {
			text-transform: capitalize;
		}
	}

	${(props) =>
		props.localTheme?.error
			? css`
					button:first-of-type {
						border: 1px solid red;
					}
			  `
			: null}

	${getProps}
`;
