import { FC } from 'react';
import styled from 'styled-components';
import { GameType } from '@modules/games/Game/constants/availableGames';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { Image } from '../../default/Image/Image';
import { getUrl } from '../../layout/MainPageOfferButton/MainPageOfferButton';
import {
	OfferBackgroundStyled,
	StyledOfferButton,
} from '../../layout/MainPageOfferButton/MainPageOfferButton.styled';

const name = GameType.HILO;
export const HiloGameButton: FC<{
	callback: () => void;
}> = ({ callback }) => {
	return (
		<Wrapper href={routingService.to(getUrl(name), true)} onClick={callback}>
			<>
				<BackgroundImage />
				<CenterCardImage />
				<LeftCardImage />
				<RightCardImage />
				<NameImage />
			</>
		</Wrapper>
	);
};

export const CenterCardImage = styled(Image).attrs({
	src: '/img/games/inhouse/hilo/front.svg',
	alt: 'Front card',
})`
	position: absolute;
	z-index: 2;
	left: 50%;
	transform: translateX(-50%);
	top: -50%;
	height: 107%;
`;

export const LeftCardImage = styled(Image).attrs({
	src: '/img/games/inhouse/hilo/back.svg',
	alt: 'Left card',
})`
	position: absolute;
	top: -47%;
	height: 84%;
	left: 15%;
	transform: rotate(9deg);
`;

export const RightCardImage = styled(Image).attrs({
	src: '/img/games/inhouse/hilo/back.svg',
	alt: 'Left card',
})`
	position: absolute;
	top: -54%;
	height: 84%;
	right: 15%;
`;

export const NameImage = styled(Image).attrs({
	src: '/img/games/inhouse/hilo/name.svg',
	alt: 'HILO',
})`
	position: absolute;
	left: 49.6%;
	transform: translateX(-50%);
	height: 26%;
	bottom: 11%;
`;

export const BackgroundImage = styled(OfferBackgroundStyled).attrs({
	src: '/img/games/inhouse/hilo/bg.png',
	alt: 'background',
})``;

export const Wrapper = styled(StyledOfferButton)`
	overflow: hidden;

	&:hover {
		${CenterCardImage} {
			transform: translateX(-50%) translateY(10%);
		}

		${LeftCardImage} {
			transform: rotate(23deg) translate(5%, 10%);
		}

		${RightCardImage} {
			transform: translate(-7%, 27%) rotate(-10deg);
		}
	}
`;
