import { actionSimple } from '../../modules/app/actionService';
import {
	DROPDOWN_ADD,
	DROPDOWN_DELETE,
	DROPDOWN_SET_ACTIVE,
	DROPDOWN_SET_CONTENT,
	DROPDOWN_TOGGLE,
} from './dropdown.constants';

export const dropdownSetActive = actionSimple(DROPDOWN_SET_ACTIVE);
export const dropdownSetContent = actionSimple(DROPDOWN_SET_CONTENT);
export const dropdownDelete = actionSimple(DROPDOWN_DELETE);
export const dropdownAdd = actionSimple(DROPDOWN_ADD);
export const dropdownToggle = actionSimple(DROPDOWN_TOGGLE);

// export const dropdownToggle = (payload) => {
// 	return (dispatch, getState) => {
// 		const state = getState();
// 		if (!state.dropdown.content[payload.id]) return of();
// 		const active = {
// 			...state.dropdown.active
// 		};
// 		const bool =
// 			payload.bool === undefined
// 				? !active[payload.id]
// 				: payload.bool;
// 		if (active[payload.id] === bool) return of();
// 		if (!bool) {
// 			delete active[payload.id];
// 		} else {
// 			active[payload.id] = bool;
// 		}
// 		return dispatch(dropdownSetActive(active));
// 	};
// };

export const dropdownGet = (id) => {
	return (dispatch, getState) => {
		const state = getState();
		return state.dropdown.content[id];
	};
};
