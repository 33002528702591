import styled, { css } from 'styled-components';
import { boxNoHeight } from '@legacyApp/client/modules/style/defaults';

export const GAMES_LIST_SPACE = 30; // px

export const StyledGamesList = styled.div<{
	$count?: number;
}>`
	${boxNoHeight};
	align-items: center;
	display: grid;
	grid-column-gap: ${GAMES_LIST_SPACE}px;
	grid-row-gap: ${GAMES_LIST_SPACE}px;
	padding: ${GAMES_LIST_SPACE}px 0;
	${(props) =>
		props.$count
			? css`
					grid-template-columns: repeat(${props.$count}, auto);
			  `
			: ''};

	@media (max-width: ${(props) => props.theme.media.maxWidthSmallTablet}) {
		grid-column-gap: ${GAMES_LIST_SPACE / 2}px;
		grid-row-gap: ${GAMES_LIST_SPACE / 2}px;
	}
`;
