import { FC } from 'react';
import styled from 'styled-components';
import { GameType } from '@modules/games/Game/constants/availableGames';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { Image } from '../../default/Image/Image';
import { getUrl } from '../../layout/MainPageOfferButton/MainPageOfferButton';
import {
	OfferBackgroundStyled,
	StyledOfferButton,
} from '../../layout/MainPageOfferButton/MainPageOfferButton.styled';

const name = GameType.LIMBO;
export const LimboGameButton: FC<{
	callback: () => void;
}> = ({ callback }) => {
	return (
		<Wrapper href={routingService.to(getUrl(name), true)} onClick={callback}>
			<>
				<BackgroundImage />
				<NumbersImage />
				<NameImage />
			</>
		</Wrapper>
	);
};

export const NumbersImage = styled(Image).attrs({
	src: '/img/games/inhouse/limbo/numbers.svg',
	alt: 'numbers',
})`
	position: absolute;
	top: 8%;
	height: 42%;
	left: 50%;
	transform: translateX(-50%);
`;

export const NameImage = styled(Image).attrs({
	src: '/img/games/inhouse/limbo/name.svg',
	alt: 'LIMBO',
})`
	position: absolute;
	left: 49.6%;
	transform: translateX(-50%);
	height: 24%;
	bottom: 19%;
`;

export const BackgroundImage = styled(OfferBackgroundStyled).attrs({
	src: '/img/games/inhouse/limbo/bg.png',
	alt: 'background',
})``;

export const Wrapper = styled(StyledOfferButton)`
	overflow: hidden;

	&:hover {
		${NumbersImage} {
			transform: translateX(-50%) scale(1.1);
		}
	}
`;
