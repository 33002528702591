import { FC } from 'react';
import styled from 'styled-components';
import { GameType } from '@modules/games/Game/constants/availableGames';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { Image } from '../../default/Image/Image';
import { getUrl } from '../../layout/MainPageOfferButton/MainPageOfferButton';
import {
	OfferBackgroundStyled,
	StyledOfferButton,
} from '../../layout/MainPageOfferButton/MainPageOfferButton.styled';

const name = GameType.DICE;
export const DiceGameButton: FC<{
	callback: () => void;
}> = ({ callback }) => {
	return (
		<Wrapper href={routingService.to(getUrl(name), true)} onClick={callback}>
			<>
				<BackgroundImage />
				<LeftDiceImage />
				<RightDiceImage />
				<NameImage />
			</>
		</Wrapper>
	);
};

export const LeftDiceImage = styled(Image).attrs({
	src: '/img/games/inhouse/dice/leftDice.png',
	alt: 'Left dice',
})`
	position: absolute;
	top: 4%;
	height: 39%;
	left: 30%;
`;

export const RightDiceImage = styled(Image).attrs({
	src: '/img/games/inhouse/dice/rightDice.png',
	alt: 'Right dice',
})`
	position: absolute;
	top: -14%;
	height: 47%;
	right: 29%;
`;

export const NameImage = styled(Image).attrs({
	src: '/img/games/inhouse/dice/name.svg',
	alt: 'DICE',
})`
	position: absolute;
	left: 49.6%;
	transform: translateX(-50%);
	height: 22%;
	bottom: 17%;
`;

export const BackgroundImage = styled(OfferBackgroundStyled).attrs({
	src: '/img/games/inhouse/dice/bg.png',
	alt: 'background',
})``;

export const Wrapper = styled(StyledOfferButton)`
	overflow: hidden;

	&:hover {
		${LeftDiceImage} {
			transform: rotate(90deg) translate(15%, -1%);
		}

		${RightDiceImage} {
			transform: translate(12%, 15%) rotate(-122deg);
		}
	}
`;
