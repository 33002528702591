import { FC } from 'react';
import { GameType } from '@modules/games/Game/constants/availableGames';
import { SPORTSBOOK_PAGE_ID } from '@modules/sportsbook/constants/sportsbook.page.config';
import { isFeatureAvailable } from '@legacyApp/client/modules/app/featureService';
import { FEATURES_TYPE } from '@legacyApp/client/config';
import { Image } from '../../default/Image/Image';
import routingService from '../../../../LegacyApp/client/modules/app/routingService';
import { HiloGameButton } from '../../games/Inhouse/HiloGameButton';
import { DiceGameButton } from '../../games/Inhouse/DiceGameButton';
import { LimboGameButton } from '../../games/Inhouse/LimboGameButton';
import { PlinkoGameButton } from '../../games/Inhouse/PlinkoGameButton';
import {
	OfferBackgroundStyled,
	StyledImageElementWrapper,
	StyledMainPageOfferButton,
} from './MainPageOfferButton.styled';

export const AVAILABLE_OFFER: Array<GameType> = [
	GameType.DICE,
	...(isFeatureAvailable(FEATURES_TYPE.PLINKO) ? [GameType.PLINKO] : []),
	GameType.LIMBO,
	GameType.HILO,
];

export const ASSETS = {
	[GameType.DICE]: {
		uri: 'casino/dice',
	},
	[GameType.LIMBO]: {
		uri: 'casino/limbo',
	},
	[GameType.HILO]: {
		uri: 'casino/hilo',
	},
	[GameType.PLINKO]: {
		uri: 'casino/plinko',
	},
	[GameType.SPORTS]: {
		uri: SPORTSBOOK_PAGE_ID.INDEX,
	},
};

type OfferNameType = GameType;

export const getUrl = (offerName: OfferNameType) => {
	return ASSETS[offerName]?.uri || `${offerName}?`;
};

export const MainPageOfferButton: FC<{
	offerName: string;
	callback?: (offerName: OfferNameType) => void;
}> = ({ offerName, callback }) => {
	const onClick = (): void => {
		console.log('offerCallback');
		if (callback) {
			callback(offerName as GameType);
		}
	};

	if (offerName === GameType.HILO) {
		return <HiloGameButton callback={onClick} />;
	}

	if (offerName === GameType.DICE) {
		return <DiceGameButton callback={onClick} />;
	}

	if (offerName === GameType.LIMBO) {
		return <LimboGameButton callback={onClick} />;
	}

	if (offerName === GameType.PLINKO) {
		return <PlinkoGameButton callback={onClick} />;
	}

	return (
		<StyledMainPageOfferButton
			$offerName={offerName as GameType}
			href={routingService.to(getUrl(offerName as GameType), true)}
			onClick={onClick}
		>
			<>
				<OfferBackgroundStyled
					src={`/img/homepage/offer/${offerName}/bg.svg`}
					alt={offerName}
				/>
				<StyledImageElementWrapper>
					<Image
						className={'offer-element'}
						src={`/img/homepage/offer/${offerName}/el.svg`}
						alt={offerName}
					/>
				</StyledImageElementWrapper>
				<span>{offerName}</span>
			</>
		</StyledMainPageOfferButton>
	);
};
