import { connect } from 'react-redux';
import {
	dropdownAdd,
	dropdownDelete,
	dropdownGet,
	dropdownToggle,
} from '../../store/dropdown/dropdown.actions';
import { Dropdown } from './dropdown';

const mapStateToProps = (state, props) => {
	return {
		isContent: !!state.dropdown.content[props.id],
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		close: (id) => dispatch(dropdownDelete(id)),
		add: (id, content) =>
			dispatch(
				dropdownAdd({
					id,
					content,
				}),
			),
		show: (id, bool) =>
			dispatch(
				dropdownToggle({
					id,
					bool,
				}),
			),
		get: (id) => dispatch(dropdownGet(id)),
	};
};

const DropdownContainer = connect(
	mapStateToProps,
	mapDispatchToProps,
)(Dropdown);

export default DropdownContainer;
